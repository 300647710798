const Steps = [
  {
    step: '1',
    summary: 'Choose the farm you would like to boost',
    desc: 'We have multiple farms accross Cameroon, see their details and pick the one you like the most',
  },
  {
    step: '2',
    summary: 'Enter the amount and the frequency for your contributions',
    desc: 'You can donate as little as $5 and engage in either a monthly plan or a one-off donation',
  },
  {
    step: '3',
    summary: 'Follow the progress of the farm',
    desc: 'We will send you detailed reports of how the farm is progressing',
  },
]

export default Steps
