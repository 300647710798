import React from 'react'
import styled from 'styled-components'

const Card = styled.div`
  display: block;
  background-color: white;
  border: 1px solid #999;
  border-radius: 3px;
  margin: 1.5em 0em;
  padding: 1em;
  @media screen and (min-width: 769px) {
    display: flex;
  }
`

const Step = styled.p`
  color: '#4DED8D',
  fontSize: '24px',
  @media screen and (min-width: 769px) {
    padding: '0em 1em',
    lineHeight: '18px',
  }
`

const StepCard = ({ step, summary, desc }) => {
  return (
    <Card>
      <Step
        style={{
          backgroundColor: '#121212',
          marginRight: '1.2em',
          borderRadius: '5px',
          width: 'fit-content',
          padding: '1em 1em'
        }}
      >
          {step}
      </Step>
      <div>
        <h4 style={{ color: '#121212', fontSize: '21px', marginTop: '12px', marginBottom: '0' }}>
          {summary}
        </h4>
        <p style={{ marginTop: '10px', color: '#4b4b4b' }}>{desc}</p>
      </div>
    </Card>
  )
}

export default StepCard
