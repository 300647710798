import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { FaFacebook, FaLinkedinIn, FaTwitter } from 'react-icons/fa'

const FooterStyles = styled.footer`
  background-color: #121212;
  display: flex;
  border-radius: 15px;
  justify-content: space-evenly;
  padding: 2em 0em 4.5em 0em;
  @media screen and (max-width: 769px) {
    display: block;
    text-align: center;
    padding: 2em 0em 1em 0em;
  }
`
const Column = styled.div`
   @media screen and (max-width: 769px) {
    margin-top: 2.1em
  }
`
const InfoItem = styled.p`
  color: white;
  font-weight: 200;
`
const MenuItem = styled.p`
  color: #4DED8D;
  font-weight: 200;
`
const SocialIcon = {
  color: '#4DED8D',
  marginRight: '1em',
  fontSize: '18px'
}

const Footer = () => {
  return (
    <FooterStyles>
      <div>
        <InfoItem>Ghana Street Nkwen <br /> Bamenda, Cameroon</InfoItem>
        <InfoItem>+237 654 814 444</InfoItem>
        <InfoItem>contact@sownet.org</InfoItem>
      </div>
      <Column>
        <p style={{ color: '#CCC' }}>Take action</p>
        <MenuItem>Boost a farm</MenuItem>
        <MenuItem>Volunteering</MenuItem>
        </Column>
      <Column>
        <p style={{ color: '#CCC' }}>Organization</p>
        <MenuItem>About</MenuItem>
        <MenuItem>FAQ</MenuItem>
        </Column>
      <Column>
        <p style={{ color: '#CCC' }}>Stay connected</p>
        <FaFacebook
          style={SocialIcon}
        />
        <FaLinkedinIn
          style={SocialIcon}
        />
        <FaTwitter
          style={SocialIcon}
        />
      </Column>
      <Column>
        <p style={{ color: '#CCC' }}>Legal</p>
        <MenuItem>Privacy policy</MenuItem>
        <MenuItem>Terms of service</MenuItem>
        </Column>
    </FooterStyles>
  )
}

export default Footer
