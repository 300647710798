// BurgerMenu.js
import React, { useState } from 'react';
import styled from 'styled-components';

const BurgerButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 33px;
  height: 33px;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const BurgerLine = styled.span`
  background-color: #121212;
  width: 100%;
  height: 2px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  z-index: 10;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const MenuItem = styled.a`
  color: white;
  font-size: 1.25rem;

  @media (min-width: 1024px) {
    color: black;
  }
`;

const HorizontalMenu = styled.div`
  display: none;
  gap: 4rem;

  @media (min-width: 1024px) {
    display: flex;
  }
`;

const BurgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative">
      <BurgerButton onClick={toggleMenu}>
        <BurgerLine />
        <BurgerLine />
        <BurgerLine />
      </BurgerButton>

      {isOpen && (
        <Overlay onClick={toggleMenu}>
          <MenuItem href="#who-we-are">Who we are</MenuItem>
          <MenuItem href="#how-it-works">How it works</MenuItem>
          <MenuItem href="#contact-us">Contact us</MenuItem>
        </Overlay>
      )}

      <HorizontalMenu>
        <MenuItem href="#who-we-are">Who we are</MenuItem>
        <MenuItem href="#how-it-works">How it works</MenuItem>
        <MenuItem href="#contact-us">Contact us</MenuItem>
      </HorizontalMenu>
    </div>
  );
};

export default BurgerMenu;
