import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }
  html {
    margin: 0; 
    padding: 0.8em; 
    background-color: #fafafa;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
  }
  @media screen and (min-width: 769px) {
    html {
        padding: 1em 4em 0em 4em; 
    }
  }
  body {
    color: #121212;
  }
  h1 {
    font-size: 48px;
  }
`

export default GlobalStyle
