import React from 'react'
import styled from 'styled-components'

const Card = styled.div`
  border: 1px solid #999;
  background-color: white;
  border-radius: 3px;
  margin-right: 2em;
  width: 250px;
  padding: 1em;
  @media screen and (max-width: 769px) {
    margin-left: 4.5em
  }
`

const MemberCard = ({ imageSrc, name, role, country }) => {
  return (
    <Card>
      <img width='100%' src={imageSrc} />
      <h3 style={{ marginBottom: '0' }}>{name}</h3>
      <p
        style={{
          fontSize: '18px',
          margin: '10px 0px',
          color: '#0F2D66',
          fontWeight: '500',
        }}
      >
        {role}
      </p>
      <p style={{ margin: '0', color: '#4b4b4b' }}>{country}</p>
    </Card>
  )
}

export default MemberCard
