import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

//Data
import Members from '../data/members'
import Steps from '../data/steps'

//Styles
import GlobalStyle from '../styles/GlobalStyle'
import styled from 'styled-components'
import './styles.css'

//Components
import Header from '../components/header'
import MemberCard from '../components/memberCard'
import StepCard from '../components/stepCard'
import Footer from '../components/footer'

//Swiper
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

const Cover = styled.section`
  display: block;
  @media screen and (min-width: 769px) {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
`
const FlexySection = styled.section`
  margin-top: 1em;  
  display: flex;
  justify-content: space-between;
  @media screen and (min-width: 769px) {
    display: none;
  }
`
const ResponsiveSection = styled.section`
  margin-top: 1em;
  @media screen and (max-width: 769px) {
    display: none;
  }
`

const Wrapper = styled.div`
  width: 100%;
  @media screen and (min-width: 769px) {
    width: 45%;
  }
`
const ImgWrapper = styled.div`
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
    width: 45%;
  }
`
const Text = styled.p`
  font-size: 27px;
  font-weight: 300;
  color: #4b4b4b;
  letter-spacing: 1px;
  @media screen and (max-width: 769px) {
    font-size: 24px;
    margin-top: 9px;
  }
`
const Industry = styled.p`
  font-size: 21px;
  font-weight: 300;
  color: #0F2D66;
  letter-spacing: 1px;
  @media screen and (max-width: 761px) {
    font-size: 18px;
    margin-bottom: 9px
  }
`
const Heading = styled.h1`
  margin: 0;
  @media screen and (max-width: 761px) {
      line-height: 54px;
    }
`

const Option = styled.p`
  font-size: 21px;
  font-weight: 300;
  color: #4b4b4b;
`

const FormSection = styled.section`
  display: flex;
  background-color: white;
  padding: 3em;
  border-radius: 15px;
  margin-bottom: 4em;
  justify-content: space-between;
  border: 1px solid #121212;
  @media screen and (max-width: 769px) {
    padding: 1em
  }
`

const FormImage = styled.div`
  width: 50%;
  @media screen and (max-width: 769px) {
    display: none
  }
`
const FormFields = styled.div`
  display: block;
  width: 50%;
  @media screen and (max-width: 769px) {
    width: 100%
  }
`
const Field = styled.div`
  margin-right: 3em;
  @media screen and (max-width: 769px) {
    margin-right: 0em
  }
`

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8.1em;
  align-items: center;
`

const SectionHeaderBlock = styled.div`
  margin-top: 8.1em;
`

const MainBtn = styled.button`
  padding: 0.5em 1em;
  font-size: 24px;
  color: #0f2d66;
  background-color: #4ded8d;
  border-radius: 3px;
  border-color: #0f2d66;
  border: 1px solid;
  box-shadow: (0px 2px 3px rgba(0, 0, 0, 0.15));
`

const IndexPage = () => {
  return (
    <>
    <React.Fragment>
      <GlobalStyle />
      <Header />
      <Cover>
        <Wrapper>
          <Industry>Non-profit organization</Industry>
          <Heading>Empowering Farmers in Cameroon</Heading> 
          <Text>
            We provide Cameronian farmers with the necessary resources such as
            land, tools, seeds, fertilizers and most importantly knowledge so
            they can build up their own agricultural operation and thrive.
          </Text>
          <MainBtn>Get involved</MainBtn>
        </Wrapper>
        <ImgWrapper>
          <StaticImage
            style={{
              borderRadius: 3,
              borderColor: '#575821',
              borderWidth: 3,
              borderStyle: 'solid',
            }}
            aspectRatio={1.1 / 1}
            alt='Gil'
            src='../images/gilkhe.jpg'
          />
        </ImgWrapper>
      </Cover>
      <SectionHeader>
        <h2 id='who-we-are'>Who we are</h2>
        <p>Meet the whole team</p>
      </SectionHeader>
      <FlexySection>
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          slidesPerGroup={1}
          loop={true}
          loopFillGroupWithBlank={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className='mySwiper'
        >
          {Members.map((member) => (
            <SwiperSlide>
              <MemberCard
                key={member.id}
                name={member.name}
                role={member.role}
                country={member.country}
                imageSrc={member.imageSrc}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </FlexySection>
      <ResponsiveSection>
        <Swiper
          slidesPerView={4}
          spaceBetween={60}
          slidesPerGroup={1}
          loop={true}
          loopFillGroupWithBlank={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className='mySwiper'
        >
          {Members.map((member) => (
            <SwiperSlide>
              <MemberCard
                key={member.id}
                name={member.name}
                role={member.role}
                country={member.country}
                imageSrc={member.imageSrc}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </ResponsiveSection>
      <SectionHeaderBlock>
        <h2 id='how-it-works'>How to get involved?</h2>
        <Option style={{ marginTop: '2em' }}>
          Option A - You can support us financially
        </Option>
      </SectionHeaderBlock>
      <div
        style={{
          padding: '2.1em',
          color: 'white',
          borderRadius: '15px',
          background:
            'linear-gradient(90deg, #0f2d66 0%, rgba(15, 45, 102, 0.75)98.28%)',
        }}
      >
        <h3
          style={{
            fontSize: '39px',
            fontWeight: '500',
            marginTop: '5px',
            marginBottom: '5px',
            letterSpacing: '1px',
          }}
        >
          Support us financially
        </h3>
        {Steps.map((step) => (
        <StepCard
          key={step.step}
          step={step.step}
          summary={step.summary}
          desc={step.desc}
        />
        ))}
        <button
          style={{ padding: '0.5em 1em', color: '#0f2d66', fontSize: '18px' }}
        >
          Support financially
        </button>
      </div>
      <Option style={{ marginTop: '3em' }}>
        Option B - You can support us physically
      </Option>
      <div
        style={{
          padding: '2.1em',
          color: 'white',
          borderRadius: '15px',
          background:
            'linear-gradient(90deg, #0f2d66 0%, rgba(15, 45, 102, 0.75)98.28%)',
        }}
      >
        <h3
          style={{
            fontSize: '39px',
            fontWeight: '500',
            marginTop: '5px',
            marginBottom: '5px',
            letterSpacing: '1px',
          }}
        >
          Become a Volunteer
        </h3>
        <p style={{ fontSize: '21px', fontWeight: '300', color: '#ccc' }}>
          Pack up your things, buy a flight ticket and come work with us
          on-site.
        </p>
        <p
          style={{
            fontSize: '21px',
            fontWeight: '300',
            color: '#ccc',
          }}
        >
          Tell us what are you good at and we'll accomodate you accordingly.
        </p>
        <button
          style={{ padding: '0.5em 1em', color: '#0f2d66', fontSize: '18px' }}
        >
          Apply now
        </button>
      </div>
      <SectionHeaderBlock>
        <h2 id='contact-us'>Contact us</h2>
      </SectionHeaderBlock>
      <FormSection>
        <FormFields>
          <h4 style={{ marginTop: 0 }}>Tell us what's on your mind</h4>
          <Field>
            <input
              style={{
                padding: '1em',
                marginBottom: '2em',
                width: '100%',
              }}
              placeholder='Name'
            />
          </Field>
          <Field>
            <input
              style={{
                padding: '1em',
                marginBottom: '2em',
                width: '100%',
              }}
              placeholder='Email'
            />
          </Field>
          <Field>
            <textarea
              style={{
                padding: '1em',
                marginRight: '3em',
                marginBottom: '2em',
                width: '100%',
              }}
              placeholder='Your message/comment/question'
            />
          </Field>
          <button
            style={{
              padding: '1em',
              backgroundColor: '#4DED8D',
              borderRadius: '3px',
              width: '30%',
            }}
          >
            Enviar
          </button>
        </FormFields>
        <FormImage>
          <StaticImage
            style={{
              borderRadius: 3,
            }}
            aspectRatio={1 / 0.6}
            alt='Gil'
            src='../images/group.jpg'
          />
        </FormImage>
      </FormSection>
    </React.Fragment>
    <Footer />
    </>
  )
}

export default IndexPage

export const Head = () => <title>Sownet</title>
