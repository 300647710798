const Members = [
  {
    id: '1',
    name: 'Gil Khenuwoh',
    role: 'President',
    country: 'Cameroon',
    imageSrc:
      'https://i.ibb.co/3chPFbC/gil.png',
  },
  {
    id: '2',
    name: 'Leonardo De Barros',
    role: 'Mentor',
    country: 'Spain',
    imageSrc:
      'https://i.ibb.co/x3F5VS2/leopic.png',
  },
  {
    id: '3',
    name: 'Manuel Rodriguez',
    role: 'Advisor',
    country: 'Denmark',
    imageSrc:
      'https://i.ibb.co/X3Vm0D1/manupic.png',
  },
  {
    id: '4',
    name: 'Tasah Tang Nora',
    role: 'Secretary',
    country: 'Cameroon',
    imageSrc:
      'https://i.ibb.co/wWjP3TG/a.png',
  },
  {
    id: '5',
    name: 'Bih Paul',
    role: 'Program Coordinator',
    country: 'Cameroon',
    imageSrc:
      'https://i.ibb.co/DLYC6LW/b.png',
  },
  {
    id: '6',
    name: 'Ndaga Rodrick',
    role: 'Farms and Products',
    country: 'Cameroon',
    imageSrc:
      'https://i.ibb.co/YW4MPDd/c.png',
  },
  {
    id: '7',
    name: 'Clinton Macualay',
    role: 'Bookkeeper',
    country: 'Cameroon',
    imageSrc:
      'https://i.ibb.co/2hYMY5Q/d.png',
  },
  // {
  //   id: '8',
  //   name: 'Clinton Macualay',
  //   role: 'Bookkeeper',
  //   country: 'Cameroon',
  //   imageSrc:
  //     'https://i.ibb.co/7z49cSg/e.png',
  // }
]

export default Members
