import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import BurgerMenu from './burger'

const HeaderStyles = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.5em;
  margin-bottom: 1.2em;
  border-bottom: 1px solid #CCC;
  @media screen and (max-width: 761px) {
    padding-bottom: 0.6em;
  } 
`

const LinkStyles = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  color: #0F2D66;
  font-size: 18px;
  text-decoration: underline;
  @media screen and (max-width: 761px) {
    display: none
  } 
`

const FakeLogo = {
  fontSize: '36px',
  fontWeight: 'bold',
  margin: '0',
}

const MainBtn = styled.button`
  padding: 0.5em 1em;
  font-size: 24px;
  color: #0f2d66;
  background-color: #4ded8d;
  border-radius: 3px;
  border-color: #0f2d66;
  border: 1px solid;
  box-shadow: (0px 2px 3px rgba(0, 0, 0, 0.15));
  @media screen and (max-width: 761px) {
    display: none
  } 
`

const Burger = styled.div`
@media screen and (min-width: 761px) {
    display: none
  } 
`

const Logo = styled.div`
  width: 20%;
  @media screen and (max-width: 761px) {
    width: 40%
  } 
`

const Header = () => {

  return (
    <HeaderStyles>
     <Logo>
        <StaticImage style={{}} alt='Logo' src='../images/sownet-logo.png' />
     </Logo>
      <BurgerMenu/>
      <MainBtn>Get involved</MainBtn>
    </HeaderStyles>
  )
}

export default Header
